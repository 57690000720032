import { NextSeo } from 'next-seo'
import { Header } from '@components/Header'
import { Footer } from '@components/Footer'
import { Link } from '@components/Link'

export default function Index() {
  return (
    <>
      <NextSeo
        title="Home Repair Estimates"
        description="Estimate home inspection and maintenance repair costs. Upload any inspection report. Get accurate local pricing for all repair items in 24 hours."
        openGraph={{
          title: 'Home Repair Estimates',
          description:
            'Estimate home inspection and maintenance repair costs. Upload any inspection report. Get accurate local pricing for all repair items in 24 hours.',
          images: [
            {
              url: 'https://majordomo.com/images/offers/mockup.png',
              width: 4014,
              height: 2360,
              alt: "Computer screen shot of Thumbtacks's Home Repair Estimate"
            }
          ]
        }}
      />

      <Header />

      <main className="font-normal md:pt-8 pt-12 md:px-0 md:space-y-20 space-y-16 leading-[1.2] text-base text-black-900">
        <div className="px-4 md:border-b-2 border-gray-300">
          <div className="flex flex-col items-center justify-center mx-auto">
            <h2 className="sm:text-title text-4xl font-medium leading-[1.1] text-center max-w-xl md:mt-12 mb-4">
              Get cost estimates right from your inspection report.
            </h2>
            <p className="text-center md:max-w-xl max-w-sm md:px-0 px-4 mb-8">
              Take charge of your home repairs with your inspection report — for
              free. Just upload your inspection report and within 24 hours
              you'll receive zip code specific repair estimates, prepared by
              industry experts. Then you can book a Thumbtack pro right from the
              repair estimate.
            </p>

            <Link href="/checkout">
              <button className="bg-blue-400 w-60 h-16 rounded-sm text-white font-medium leading-[1.1] mb-16 md:text-base text-lg">
                Upload your report
              </button>
            </Link>

            <img
              src="/images/home/desktop_hero.jpg"
              className="w-full max-w-4xl md:inline hidden"
            />
            <img
              src="/images/home/mobile_hero.jpg"
              className="w-full max-w-4xl md:hidden inline"
            />
          </div>
        </div>

        <div className="container px-8 flex flex-col items-center justify-center mx-auto md:space-y-20 space-y-8">
          <h2 className="text-title font-medium leading-[1.1] text-center">
            How it works
          </h2>

          <div className="space-y-12">
            <div className="grid md:grid-cols-2 grid-cols-1">
              <img
                src="/images/home/howitworks1.png"
                className="max-w-xl w-full md:mb-0 mb-4"
              />
              <div className="flex flex-col justify-center md:max-w-xs max-w-sm mx-auto space-y-2">
                <p className="font-black md:text-sm text-xs md:tracking-[0.96px] tracking-[0.72px] text-blue-400 uppercase md:mb-2 mb-0">
                  Step one
                </p>
                <p className="font-medium leading-[1.1] text-2xl md:max-w-none max-w-xs">
                  Upload your home inspection report.
                </p>
                <p className="leading-[1.2]">
                  Drop the PDF of your inspection report into the tool.
                </p>
              </div>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1">
              <div className="flex flex-col justify-center max-w-sm mx-auto space-y-2 md:order-1 order-2">
                <p className="font-black md:text-sm text-xs md:tracking-[0.96px] tracking-[0.72px] text-blue-400 uppercase md:mb-2 mb-0">
                  Step two
                </p>
                <p className="font-medium leading-[1.1] text-2xl max-w-sm">
                  Access your free home repair estimate within 24 hours.
                </p>
                <p className="leading-[1.2]">
                  Your home repair estimate will be reviewed by industry experts
                  with zip code specific repair estimates.{' '}
                  <span className="md:inline hidden">
                    Plus, you can discuss your repair estimate with a home
                    specialist for free.
                  </span>
                </p>
              </div>
              <img
                src="/images/home/howitworks2.png"
                className="md:order-2 order-1 max-w-xl w-full md:mb-0 mb-4"
              />
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1">
              <img
                src="/images/home/howitworks3.png"
                className="max-w-xl w-full md:mb-0 mb-4"
              />
              <div className="flex flex-col justify-center max-w-md mx-auto space-y-2">
                <p className="font-black md:text-sm text-xs md:tracking-[0.96px] tracking-[0.72px] text-blue-400 uppercase md:mb-2 mb-0">
                  Step three
                </p>
                <p className="font-medium leading-[1.1] text-2xl">
                  Book Thumbtack pros to complete the repairs directly in your
                  report.
                </p>
                <p className="leading-[1.2]">
                  See a list of qualified home pros, ratings, reviews and
                  service estimates with the click of a button.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-8 flex flex-col items-center justify-center mx-auto space-y-8">
          <h2 className="text-title font-medium leading-[1.1] text-center md:pt-16">
            See how much repairs can cost.
          </h2>

          <Link href="/checkout">
            <button className="bg-blue-400 w-60 h-16 rounded-sm text-white font-medium leading-[1.1] mb-8 md:text-base text-lg">
              Upload your report
            </button>
          </Link>
        </div>

        <div className="px-8 bg-gray-50 md:pt-32 pt-16 md:pb-20 pb-16 striped-background">
          <div className="container mx-auto md:space-y-16 space-y-8">
            <h2 className="text-title font-medium leading-[1.1] text-center">
              What you get
            </h2>

            <div className="grid md:grid-cols-2 grid-cols-1 gap-8 max-w-5xl mx-auto">
              <div className="flex flex-col md:space-y-4 mx-auto md:mb-0 mb-4">
                <img
                  src="/images/home/zipcode_repairs_desktop.jpg"
                  className="md:max-w-full sm:max-w-md max-w-full rounded-md mb-4"
                />
                <p className="font-medium leading-[1.1] text-2xl md:mb-0 mb-2">
                  Zip code specific repair estimates.
                </p>
                <p className="max-w-md leading-[1.2]">
                  Understand the cost estimates for labor and materials based on
                  where you live. Every home repair estimate is reviewed by
                  industry experts before it's sent to you.
                </p>
              </div>
              <div className="flex flex-col md:space-y-4 mx-auto">
                <img
                  src="/images/home/prioritized_repairs_desktop.jpg"
                  className="md:max-w-full sm:max-w-md max-w-full rounded-md mb-4"
                />
                <p className="font-medium leading-[1.1] text-2xl md:mb-0 mb-2">
                  Prioritized repairs by severity and urgency.
                </p>
                <p className="max-w-md leading-[1.2]">
                  Your cost estimate tells you what repairs are listed in your
                  inspection report, the level of urgency and the cost estimate.
                  Then you can easily see pros you can hire for each repair
                  right in your cost estimate.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-8 flex flex-col items-center justify-center mx-auto md:space-y-8 space-y-4">
          <h2 className="text-title font-medium leading-[1.1] sm:text-center w-full">
            All features
          </h2>

          <div className="grid md:grid-cols-2 grid-cols-1 md:gap-24 gap-8">
            <div className="flex flex-col space-y-4">
              <p className="text-2xl">Free interactive report</p>
              <div className="flex items-center space-x-4">
                <img src="/images/home/greencheck.png" className="w-4 h-4" />
                <p>Repair and maintenance recommendations</p>
              </div>
              <div className="flex items-center space-x-4">
                <img src="/images/home/greencheck.png" className="w-4 h-4" />
                <p>Full and detailed analysis of all repairs</p>
              </div>
              <div className="flex items-center space-x-4">
                <img src="/images/home/greencheck.png" className="w-4 h-4" />
                <p>Free access to a home specialist to answer questions</p>
              </div>
            </div>

            <div className="flex flex-col space-y-4">
              <p className="text-2xl">Data security</p>
              <div className="flex items-center space-x-4">
                <img src="/images/home/greencheck.png" className="w-4 h-4" />
                <p>Easy and secure built-in sharing</p>
              </div>
              <div className="flex items-center space-x-4">
                <img src="/images/home/greencheck.png" className="w-4 h-4" />
                <p>Lifetime account access</p>
              </div>
            </div>
          </div>

          <Link href="/checkout">
            <button className="bg-blue-400 w-60 h-16 rounded-sm text-white font-medium leading-[1.1] md:mb-0 mt-8 md:text-base text-lg">
              Upload your report
            </button>
          </Link>
        </div>

        <Footer />
      </main>
    </>
  )
}
