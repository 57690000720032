import NextLink from 'next/link'
import { ReactNode } from 'react'

interface LinkProps {
  type?: 'outbound' | 'legacy'
  href: string
  children: ReactNode
}

export function Link(props: LinkProps) {
  switch (props.type) {
    case 'outbound':
      return (
        <a
          href={props.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.children}
        </a>
      )
    case 'legacy':
      return (
        <a href={props.href} className="text-brand hover:underline">
          {props.children}
        </a>
      )
    default:
      return (
        <NextLink href={props.href}>
          <a className="text-brand hover:underline">{props.children}</a>
        </NextLink>
      )
  }
}
